import React from "react";
import {
  ActionButton,
  ButtonDelete,
  ButtonCancel,
  ModalComponentWrapper,
  TitleDelete,
} from "./styled";


const ModalDeleteCourse = ({ onOk, onCancel, visibleModal}) => {

  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
      className='modal__import'
    >
      <TitleDelete>こちらの受講予定講座を削除してもよろしいでしょうか？</TitleDelete>
      <ActionButton>
        <ButtonDelete onClick={onOk}>削除</ButtonDelete>
        <ButtonCancel onClick={onCancel}>キャンセル</ButtonCancel>
      </ActionButton>
    </ModalComponentWrapper>
  );
};

export default ModalDeleteCourse;
