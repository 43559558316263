import styled from "styled-components";

export const Wrap = styled.div`
  padding-top: 2.8rem;
  padding-bottom: 3.25rem;
  padding-inline: 5rem;
  min-height: 80vh;
  .ant-tabs-content-holder .ant-list.ant-list-split{
    padding-top: 0;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  @media only screen and (max-width: 1280px) {
    padding-inline: 10px;
  }

  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 10px;

    .ant-select {
      min-height: 40px !important; 
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .ant-select-selector{
        min-height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item{
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-form-item-row{
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label{
      text-align: start;
    }

    .ant-select-disabled{
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === 'error'
          ? `1px solid ${theme.error_ant} !important`
          : ''};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === 'error' ? 'none' : ''};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after{
      content: "" !important;
    }
  }

  .no_information {
    font-weight: 600;
    font-size: 18px;
    color: #7D0006;
    @media only screen and (max-width: 1280px) {
      font-size: 16px;
    }
  }

  .ant-tabs-nav-list {

    .ant-tabs-tab  {
      padding: 12px 14px;
      margin:0;
    }

    .ant-tabs-tab-active {
      background-color: #7D0006;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
  .list_scheduled{
    margin-top:20px;
  }
`;

export const WearInfomation = styled.div`
  display: flex;
  .ant-form-item:nth-child(2) {
    margin: 0 10px;
  }
`

export const TitleInfo = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 30px;
  color: #6D737A;
`

export const NodataListScheduled = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 30px;
  color: #6d737a;
`
export const ButtonNewCourse = styled.div`
  width: 192px;
  height: 45px;
  padding: 4px 16px;
  background: rgb(125, 0, 6);
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }
`

export const ListCurriculum = styled.div`
  width: 100%;
  height: auto;
  overflow-y: auto;
`
export const LoadMoreData = styled.p`
  display: flex;
  justify-content: center;
`

export const LoadMoreBtn = styled.button`
  border: none;
  font-weight: 700;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  background-color: #7d0006;
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  :disabled{
    cursor: not-allowed;
  }
`
