import { Col } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: auto;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #C9C9C9;
  background: ${props => props?.active ? "#CDE2FF" : "#F5F5F5"};
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wrapper__left {
    width: 100%;

    .btn__status {
      margin-bottom: 6px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 162.5% */
      text-transform: uppercase;
      border-radius: 5px;
      background: ${props => props?.active ? "#00003A" : "#6D737A"};
      display: flex;
      align-items: center;
      cursor: default;
    }
  }
`

export const CourseAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px;

  button {
    width: 102px;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    &:hover{
      opacity: 0.7;
    }
  }

  .btn__actived {
    border-radius: 8px;
    background: #00875D;
    color: #FFF;
    :disabled{
      background: #6D737A;
      cursor: not-allowed;
      &:hover{
        opacity: 1;
      }
    }
  }

  .btn__detail {
    border-radius: 8px;
    background: #D9BE22;
    color: #7D0006;
  }

  .btn__delete {
    border-radius: 8px;
    background: #F0A7AB;
    color: #7D0006;
  }
`

export const ColWraper = styled(Col)`
  display: grid;
  row-gap: 10px;
  .info_title{
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }

  .info_data{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  @media only screen and (max-width: 1280px ) {
    .info_title{
      font-size: 10px;
    }

    .info_data{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 768px ) {
   margin-bottom: 25px;
  }
`