import React from 'react';
import { Result } from 'antd';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const HTML = styled.div`
	width: 100%;

	.ant-result-subtitle {
		font-size: 26px;
		color: #000;
		margin-top: 36px;
	}

	.noti_reason {
		margin-top: 30px;
		padding: 16px;
		width: 100%;
		height: 100%;

		.noti_title {
			font-size: 20px;
		}

		.noti_content {
			margin-top: 20px;

			div {
				padding: 6px;
				font-size: 16px;
				text-align: center;
			}
		}
	}
`

function PageNotAuthen() {
  const [t] = useTranslation('common');
  const location = useLocation()

	return (
		<HTML>
			<Result
				status="403"
				title={`${location?.state?.code_error || 404}`}
				subTitle={t("noti_authen")}
				extra={
					<div className='noti_reason'>
						<h1 className='noti_title'>{t("authen_title")}</h1>
						<div className='noti_content'>
							<div>※{t("authen_token")}</div>
							<div>※{t("authen_access")}</div>
							<div>※{t("not_student")}</div>
							<div>※{t("token_expired")}</div>
						</div>
					</div>
				}
			/>
		</HTML>
	);
}

export default PageNotAuthen;
