import React from 'react'
import { Content, ImageMaintenance, InfoMaintenance, MaintenanceWeb, Title, Wrap } from './styled'
import { WEB_MAINTENANCE } from 'Assets/Image'
import { useSelector } from 'react-redux';
import { SHOW_NOTI } from 'Utils/data-default';

export default function Maintenance() {
  const {
		info_maintenance_student
  } = useSelector((state) => state.student);
  return (
    <Wrap>
      <MaintenanceWeb>
        <InfoMaintenance>
          <Title>
              只今メンテナンス中
          </Title>
          <Content>
            現在システムメンテナンスのため、こちらのページはご利用いただけません。<br />
            ご不便をお掛けいたしますが、ご理解いただきますようお願い申し上げます。
          </Content>
          {SHOW_NOTI[info_maintenance_student?.is_show_message ] === 'show'  && <Content>
            ＊ {info_maintenance_student?.reason_maintenance || ''}
          </Content>}
        </InfoMaintenance>
        <ImageMaintenance className='img__maintenance' src={WEB_MAINTENANCE} alt='sever-maintenance' />
      </MaintenanceWeb>
      </Wrap>
  )
}
