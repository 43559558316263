import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
`;

const TextLoading = styled.p`
  margin-bottom: 0;
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
`;
const spinAnimation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0.25; }
`;

const SpinnerContainer = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;
  padding: 10px;
  border-radius: 10px;
`;

const Bar = styled.div`
  width: 6%;
  height: 16%;
  background: #FFF;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: ${spinAnimation} 1s linear infinite;
`;

const Bar1 = styled(Bar)`
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
`;

const Bar2 = styled(Bar)`
  transform: rotate(45deg) translate(0, -130%);
  animation-delay: -0.0833s;
`;

const Bar3 = styled(Bar)`
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.1667s;
`;

const Bar4 = styled(Bar)`
  transform: rotate(135deg) translate(0, -130%);
  animation-delay: -0.25s;
`;

const Bar5 = styled(Bar)`
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.3333s;
`;

const Bar6 = styled(Bar)`
  transform: rotate(225deg) translate(0, -130%);
  animation-delay: -0.4167s;
`;

const Bar7 = styled(Bar)`
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.5s;
`;

const Bar8 = styled(Bar)`
  transform: rotate(315deg) translate(0, -130%);
  animation-delay: -0.5833s;
`;

const ButtonCloseLoading = styled.button`
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  background: #E0E0E0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #FFFFFF;
  font-family: 'Public Sans';
  height: 40px;
  min-width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LoadingModal = (props) => {
  const [showButtonModal, setShowButtonModal] = useState(false)
  const [showModal, setShownModal] = useState(true)
  const [t] = useTranslation('student');
  useEffect(() => {
    setTimeout(() => {
      setShowButtonModal(true)
    }, 60000)
  }, [])
  const onCloseModal = () => {
    props.cancelEvent()
    setShownModal(false)
  }
  return (
    <>
      {
        showModal && 
          <ModalOverlay>
            <ModalContent>
              <SpinnerContainer>
                <Bar1 />
                <Bar2 />
                <Bar3 />
                <Bar4 />
                <Bar5 />
                <Bar6 />
                <Bar7 />
                <Bar8 />
              </SpinnerContainer>
              <TextLoading>{props?.textLoading}</TextLoading>
              {showButtonModal && <ButtonCloseLoading onClick={onCloseModal}>{t("cancel")}</ButtonCloseLoading>}
            </ModalContent>
          </ModalOverlay >
      }
    </>

  );
};

export default LoadingModal;
