/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Wrap,
  WearInfomation,
  TitleInfo,
  ButtonNewCourse,
  ListCurriculum,
  LoadMoreData,
  LoadMoreBtn,
  NodataListScheduled
} from "./styled";
import ExpectedCourse from "./ExpectedCourse";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row } from "antd";
import BreadcrumbComponent from "Components/Breadcrumb";
import FormInput from "Components/Form/FormInput";
import { errorForm, RESPONSE_STATUS } from "Constants";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import actions from "Store/Actions";

import { regex } from "Utils/regex";
import { useHistory, useLocation, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { setLocalStorage, STORAGE } from 'Utils/storage';
import LoadingModal from "Pages/LoadingModal";

const {
  getStudentInformation,
  backAuthen,
  getListCurriculum,
} = actions;
const PageOption = () => {
  const [t] = useTranslation('student');
  const validationSchema = yup
  .object({
    studentID: yup
      .string()
      .typeError(errorForm.IS_STRING)
      .required(t(("message.id_is_required")))
      .matches(regex.interger8digit , t("id_field_incorrect_format")),
  })
  .required();
  
  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    inforStudent,
    listCurriculum,
    studentID,
    totalListCurriculum
  } = useSelector((state) => state.student);
  window.history.replaceState({}, document.title)
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { setValue, formState: { errors }, } = form;
  const scrollToForm = useRef();
  const [page, setPage] = useState(1);
  const [size] = useState(10);

  const loadMoreItems = () => {
    setPage(pre=>pre+1)
  };
  useEffect(() => {
    if (!_.isEmpty(errors)) {
      scrollToForm.current.scrollIntoView();
    }
  // eslint-disable-next-line no-use-before-define
  }, [errors]);

  const handleDirectDetail = (curriculum,index) => {
    if (curriculum) {
      if (curriculum.version > process.env.REACT_APP_VERSION) {
         history.push({
            pathname: `/student/curriculum/${studentID}/${index}`,
            state: { 
              curriculumID: curriculum?.curriculumID
            }
          })
      }
      else {
        history.push({
            pathname: `/student/curriculum-detail/${studentID}/${index}`,
            state: { 
              curriculumID: curriculum?.curriculumID
            }
          })
      }
     
    } else {
      history.push({
        pathname: `/student/curriculum/${studentID}/create`,
        state: { 
          curriculumID: null
        }
      })
    }
  }

  useEffect(() => {
    if (location.search) {
      const token = location.search.split("=")[1]
      setLocalStorage(STORAGE.USER_TOKEN, token)
    }
  }, [])
  


  const onGetInformationStudent = (id) => {
    setLoading(true)
    dispatch(
      getStudentInformation(id, (action, res) => {
        const { data } = res;
        if(res.status !== RESPONSE_STATUS.SUCCESS) {
          setLoading(false)
          return;
        }
        // if (!_.isEmpty(data) && !data?.generatedClassScheduleID) {
        for (const [key, value] of Object.entries(data)) {
          setTimeout(()=>{
            setValue(key, value);
          },0)
        }
        // }
        // else {
        //   dispatch(getInforStudentSaved(data.studentID, (action, infor) => {
        //    const { data } = infor;
        //    if (!_.isEmpty(data) && !data.generatedClassScheduleID) {
        //     const { aspirational } = data
        //     for (const [key, value] of Object.entries(aspirational)) {
        //       console.log("🚀 ~ file: index.jsx:120 ~ dispatch ~ key:", key)
        //       setTimeout(()=>{
        //         setValue(key, value);
        //       },0)
        //     }
        //    }
        //   }))
        // }
        if (res.status === RESPONSE_STATUS.SUCCESS) {
          if (listCurriculum.length !== 0 && page === 1) {
            setLoading(false)
            return
          }
          setLoading(true)
          dispatch(getListCurriculum({
            studentID : id,
            page: page-1,
            size,
          }, (action, res) => {
            setLoading(false)
          }))
        }
      })
    );
  };

  useEffect(() => {
    if(location.pathname === "/") {
      history.push({
        pathname: `/error`,
        state: {
          code_error: '404'
        }
      })
      dispatch(backAuthen());
    }
  }, []);

  useEffect(() => {
    if (params.hasOwnProperty("ID")) {
      setValue("studentID", params.ID);
      onGetInformationStudent(params.ID)
    }
  }, [params,page]);
  

  const handleResetForm = () => {
    if (inforStudent.generatedClassScheduleID === null) {
      window.location.reload(false)
    }
  }

  useEffect(() => {
    if(location?.state?.noUni) {
      handleResetForm()
    }
  }, [])
  const reset = () => {
    // setResetPage(pre => pre + 1)
    // setPage(1)
  }
  return (
      <Wrap>
        <BreadcrumbComponent reset={handleResetForm} name={t("student_information")}/>
        <FormProvider {...form}>
          <form
            // onSubmit={handleSubmit(onSubmit)}
            id="information-form"
            autoComplete="off"
            ref={scrollToForm} 
          >
            <Row gutter={[32]}>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("studentID")}
                  name="studentID"
                  type="text"
                  disabled={!!params?.ID}
                />
              </Col>
              <Col span={24} lg={12}>
                <FormInput label={t("student_name")} name="studentName" disabled={true} />
              </Col>
            </Row>
            <Row gutter={[32]} justify="start">
              {inforStudent?.universityName !== null && inforStudent?.majorName !== null && inforStudent?.departmentName !== null ? (
                <Col span={24} lg={12} className="wear_info">
                  <TitleInfo>{t("wear_information")}</TitleInfo>
                  <WearInfomation>
                    <FormInput name="universityName" disabled={true} />
                    <FormInput name="departmentName" disabled={true} />
                    <FormInput name="majorName" disabled={true} />
                  </WearInfomation>
                </Col>
              ) : (
                <Col span={24} lg={12} className="wear_info">
                  <TitleInfo>{t("wear_information")}</TitleInfo>
                  <span className="no_information">{t("no_information")}</span>
                </Col>
              )}
            </Row>

            <Row gutter={[32]} justify="start">
              <Col span={24} lg={12} className="wear_info">
                <ButtonNewCourse onClick={() => handleDirectDetail()}>
                  受講予定講座作成
                </ButtonNewCourse>
              </Col>
            </Row>
          </form>
      </FormProvider>
        <BreadcrumbComponent name={t("List_of_scheduled_courses")} className="list_scheduled"/>
        <ListCurriculum>
          {listCurriculum.length > 0  ? (
            listCurriculum?.map((curriculum , index) => (
              <ExpectedCourse reset={reset} key={curriculum?.curriculumID} index={index} curriculum={curriculum} studentID={studentID} handleDirectDetail={handleDirectDetail}/>
            ))
        ) : <NodataListScheduled>
            {t('no_data_list_of_scheduled_courses_1')}<br/>
            {t('no_data_list_of_scheduled_courses_2')}
        </NodataListScheduled>}
      </ListCurriculum>
      {totalListCurriculum > 10 && <LoadMoreData>
        <LoadMoreBtn onClick={loadMoreItems} hidden={listCurriculum.length === totalListCurriculum }>
          もっと見る
        </LoadMoreBtn>
      </LoadMoreData>}
        {loading && <LoadingModal textLoading={t("generating_scheduled_courses")}/>}
      </Wrap>
    )
};

export default PageOption;
