import styled from "styled-components";

export const Wrap = styled.div`
  min-height: calc( 100vh - ( 6.6rem + 15rem ) ) ;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImageMaintenance = styled.img`
  width: 700px;
  object-fit: cover;
  @media only screen and (max-width: 768px) {
    width: 400px;
  }
`
export const MaintenanceWeb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    flex-wrap: wrap-reverse;
  }
`

export const InfoMaintenance = styled.div`
  padding: 10px;
  width: 800px;
`
export const Title = styled.p`
  color: #7d0006;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`
export const Content = styled.p`
  color: #000;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
  word-break: break-all;
  white-space: pre-wrap;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`