import React, { useState } from "react";
import {
  Wrapper,
  CourseAction,
  ColWraper
} from "./styled";
import { Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { convertValueToText } from "Utils/convertValue";
import { useDispatch } from "react-redux";
import actions from "Store/Actions";
import { RESPONSE_STATUS } from "Constants";
import ModalDeleteCourse from "../ModalDeleteCourse";

const {
  activeAiCurriculum,
  deleteAiCurriculum,
  updateDeleteAiCurriculum
} = actions;
const ExpectedCourse = ({index, curriculum, handleDirectDetail, reset }) => {
  const [showDelete, setShowDelete] = useState(false)
  const [t] = useTranslation('student');
  const dispatch = useDispatch();
  const DATA_RESULT_LESSON = [
    {
      title: t("student:university_name"),
      dataIndex: "大学名",
      key: "大学名",
      data: "大学名",
      name: "universityName",
    },
    {
      title: t("student:faculty_name"),
      dataIndex: "学部名",
      key: "学部名",
      data: "学部名",
      name: "departmentName",
    },
    {
      title: t("student:department_major"),
      dataIndex: "学科・専攻",
      key: "学科・専攻",
      data: "学科・専攻",
      name: "majorName",
    },
    {
      title: t("student:schedule_method"),
      dataIndex: "日程・方式",
      key: "日程・方式",
      data: "日程・方式",
      name: "scheduleMethod",
    },
    {
      title: t("student:course_period"),
      dataIndex: "受講期間",
      key: "受講期間",
      data: "受講期間",
      name: "time",
    },
    {
      title: t("student:social_science"),
      dataIndex: "文系・理系",
      key: "文系・理系",
      data: "文系・理系",
      name: "socialScience",
    },
    {
      title: t("student:branch_name"),
      dataIndex: "枝名",
      key: "枝名",
      data: "枝名",
      name: "branchAspiration",
    },
    {
      title: t("student:excitement"),
      dataIndex: "奮起度",
      key: "奮起度",
      data: "奮起度",
      name: "奮起度",
    },
    {
      title: t("student:praise_resistance"),
      dataIndex: "誉め耐性",
      key: "誉め耐性",
      data: "誉め耐性",
      name: "誉め耐性",
    },
    {
      title: t("student:stress_tolerance"),
      dataIndex: "ストレス耐性",
      key: "ストレス耐性",
      data: "ストレス耐性",
      name: "ストレス耐性",
    },
    {
      title: t("student:upward_trend"),
      dataIndex: "上昇志向",
      key: "上昇志向",
      data: "上昇志向",
      name: "上昇志向",
    },
    {
      title: t("student:autonomy"),
      dataIndex: "自立性",
      key: "自立性",
      data: "自立性",
      name: "自立性",
    },
  ];

  const handleActive = async () => {
    dispatch(activeAiCurriculum({
      studentID: curriculum.studentID,
      curriculumID: curriculum.curriculumID
    }, (action, res) => {
      if(res.status === RESPONSE_STATUS.SUCCESS) {
        reset()
      }
    }))
  }
  const handleDelete = async () => {
    dispatch(deleteAiCurriculum({
      curriculum_ids: [curriculum.curriculumID]
    }, (action, res) => {
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        dispatch(updateDeleteAiCurriculum({
          curriculumID: curriculum.curriculumID
        }, (action, res) => {
          setShowDelete(false)
        }))
      }
    }))
  } 
  return (
    <Wrapper active={curriculum?.is_activate}>
      <div className="wrapper__left">
        <Button className="btn__status" disabled>受講予定講座 {index + 1}</Button>
        <Row gutter={[16, 16]}>
          {DATA_RESULT_LESSON.map((it) => (
            <>
              {it.name === 'branchAspiration' && !curriculum[`${it.name}`] ? <> </>: <ColWraper /* xl={2} lg={8} sm={12} xs={24} */ span={4} key={it.key}>
                {<label htmlFor="html" className="info_title">
                  {it.title}
                </label>}
                {it.key === "奮起度" || it.key === "誉め耐性" || it.key === "ストレス耐性" || it.key === "上昇志向" || it.key === "自立性" ? (
                  <span className="info_data">
                    {
                      convertValueToText(curriculum[`${it.name}`])
                    }
                  </span>
                ) : (
                  <span className="info_data">
                    {it.name === "time"
                      ? `${dayjs(curriculum[`startTime`]).format('YYYY/MM/DD') || ""} ~ ${dayjs(curriculum[`endTime`]).format('YYYY/MM/DD') || ""
                      }`
                      : curriculum[`${it.name}`]}
                  </span>
                )}
              </ColWraper>}
            </>
          ))}
        </Row>
      </div>
      <CourseAction>
        <button className="btn__actived" disabled={curriculum?.is_activate === 1} onClick={handleActive}>{curriculum?.is_activate === 1 ? '有効' : '無効'}</button>
        <button className="btn__detail" onClick={() => handleDirectDetail(curriculum,index+1)}>編集</button>
        <button className="btn__delete" onClick={()=>setShowDelete(true)}>削除</button>
      </CourseAction>
      <ModalDeleteCourse
        onOk={handleDelete}
        onCancel={()=>setShowDelete(false)}
        visibleModal={showDelete}
      />
    </Wrapper>
  )
}

export default ExpectedCourse